import React from "react";
import { withStyles } from "@material-ui/core";
import foundationImg from "../../../images/surdur/ssf/foundation.png";
import ssfImg from "../../../images/surdur/ssf/ssf.jpg";
import yesilGunlerImg from "../../../images/surdur/ssf/yesil-gunler.jpg";
import temizlikGunuImg from "../../../images/surdur/ssf/temizlik-gunu.jpg";
import TwoColumn from "../../../components/home/TwoColumn";
import ManifestCenter from "../../../components/home/ManifestCenter";
import SubPageBase from "../../../components/SubPageBase";

const styles = (theme) => ({
  root: {
    fontSize: "1.5em"
  },
  content: {
    backgroundColor: "#EFF2F7",
    paddingBottom: "10em",

    "& div:first-child": {
      marginBottom: "2em"
    }

  }
});

const IndexPage = ({ classes }) => (
  <SubPageBase title={"SOSYAL SORUMLULUK VE FARKINDALIK"} img={ssfImg} className={classes.root} objectPosition="top">
    <div className={classes.content}>
      <ManifestCenter
        text={
          <p>Daha sürdürülebilir bir gelecek için biz tek başımıza yeterli değiliz, bunu biliyoruz... Toplum olarak en
            büyük ihtiyacımız farkında olmak. Bu yüzden Decathlon olarak sürdürülebilir kalkınma çalışmalarımızda,
            sosyal sorumluluk ve farkındalık büyük önem taşır.</p>
        }
      />

      <TwoColumn
        imageSrc={foundationImg}
        header="Decathlon Vakfı"
        text={
          <p>Decathlon Vakfı, 2005 yılından beri takım arkadaşlarımızın projelerini hayata geçirir. Hala gururla
            destekledikleri bu projelerin amacı spora erişemeyenleri desteklemek ve sporu sosyal entegrasyon için bir
            araç haline getirmektir.<br />
            <a href="https://sustainability.decathlon.com/decathlon-foundation-sport-has-so-much-to-give-us">Daha
              fazlasını keşfet</a>
          </p>
        }
        textFirst={true}
      />

      <TwoColumn
        imageSrc={temizlikGunuImg}
        header="Dünya Temizlik Günü"
        text={
          <p>
            2018'den beri Decathlon Türkiye olarak mağazalarımızın bulunduğu tüm şehirlerde temizlik organizasyonları
            düzenliyoruz. Paydaşlarımızla yaptığımız bu organizasyonlarda 5000 kişi ile 66 ton ağırlığında 5400 torba
            çöp topladık.
          </p>
        }
      />

      <TwoColumn
        imageSrc={yesilGunlerImg}
        header="Yeşil Günler"
        text={
          <p>
            Tüketmeden üretmek, sorumlu alışveriş, ürün ömrünün uzatılması daha sürdürülebilir bir dünya için bu bilinç
            hepimize lazım. İşte tam da bu yüzden Yeşil Günler etkinliğimizde dikiş atölyesinde artık kumaşlardan yeni
            ürünler dikiyoruz, bisikletlerimizi atölyemizde tamir ediyoruz ve fidan dikiyoruz. Çok daha fazlasını
            yapacağımız yeni etkinliklerimizi öğrenmek için sosyal medya hesabımızı takip edebilirsin.
          </p>
        }
        textFirst={true}
      />


      <TwoColumn
        imageSrc={temizlikGunuImg}
        header="Sürdürülebilir Kalkınma Eğitimleri"
        text={
          <p>
            Decathlonlular olarak doğayı çok seviyoruz ama sürdürülebilir bir dünya yaratmak için bu yeterli değil. Bu
            yüzden tüm takım arkadaşlarımıza iklim krizini, sürdürülebilir kalkınmayı, Decathlon'un sürdürülebilir
            kalkınma projelerini ve ecodesign yaklaşımımızı anlattığımız eğitimler veriyoruz.
          </p>
        }
      />

    </div>
  </SubPageBase>
);

export default withStyles(styles)(IndexPage);
